export * from 'core/shared/utils'

export const getLanguages = () => {
  return [
    { label: 'English', value: 'en' },
    { label: 'Italiano', value: 'it' },
    { label: 'Japanese', value: 'jp' },
    { label: 'Chinese (Traditional)', value: 'ch' },
    { label: 'Français', value: 'fr' },
  ]
}
