import { RouteComponentProps, StaticContext } from 'react-router'
import { T, __ } from 'stylewhere/i18n'
import CoreShippingStart, { ShippingStartParams } from 'core/pages/Shipping/ShippingStart'
import { askUserConfirmation, hideToast, showToast, showToastError } from 'stylewhere/shared/utils'
import { OperationReadingState, Router, Sounds } from 'stylewhere/shared'
import { Shippings, ShippingSelectData } from 'stylewhere/api'

export default class ShippingStart extends CoreShippingStart<
  RouteComponentProps<ShippingStartParams, StaticContext, OperationReadingState>
> {
  onSelectAsnError = async (err, selected: ShippingSelectData) => {
    const message: string = err?.message || ''
    if (message.startsWith('ASN_CLOSED_BY_CEGID:')) {
      const confirmForceClose = await askUserConfirmation(__(T.misc.confirm), __(T.custom.confirm_force_received_text))
      if (confirmForceClose) {
        // chiudi la spedizione passando l'attributo closedByCegid a true
        if (selected && selected.id && selected.code) {
          const asnAttributes = selected.attributes ?? {}

          const toastId = showToast({
            title: __(T.misc.processing),
            description: __(T.messages.operation_in_progress),
            status: 'info',
          })
          try {
            await Shippings.confirmAsn(this.operation, selected.code, {
              ...asnAttributes,
              transactionDate: new Date().toISOString(),
              closedByCegid: true,
            })
            showToast({
              title: __(T.misc.success),
              description: __(T.messages.operation_success),
              status: 'success',
            })
            if (toastId) hideToast(toastId)
            Sounds.success()
            Router.navigate('/shipping/:opCode', { opCode: this.operation.code })
          } catch (error) {
            showToastError(error, __(T.error.error), this.isModal)
            if (toastId) hideToast(toastId)
          }
          return
        }

        // error with data
        showToast({
          title: __(T.error.error),
          description: __(T.error.field_required, { label: 'ASN' }),
          status: 'error',
        })
        Sounds.error()
        this.navigateToAsn(selected?.id)
        return
      }

      // user don't want to force close
      this.navigateToAsn(selected?.id)
      return
    }

    // error not managed
    showToastError(err, 'Start ASN Error', this.isModal)
  }
}
