export default {
  titles: {
    encoding: '編碼',
    inbound: '入境',
    user_info: '使用者資訊',
    settings: '設定',
    reset_daily_items: '重置日常項目',
    last_parcels: '最後的包裹',
    select_item: '選擇物品',
    select_workstation: '選擇工作站',
    select_language: '選擇語言',
    support: '支援',
    select_printer: '選擇印表機',
    shipment_to_confirm: '出貨確認',
    sorting_details: '公開細節',
    inventories: '庫存',
    pin_modal: '輸入 PIN 碼以強制關聯',
    shipments: '出貨量',
  },
  fields: {
    current_location: '目前位置',
    identified_item: '已識別物品',
    ignored_items: '被忽略的項目',
    ignored_with_reason_items: '有理由忽略的項目',
    error_items: '錯誤項目',
    warning_items: '警告事項',
    umg: 'Umg',
    custom_class: '客户等級',
    product_code: '產品編碼',
    as400username: '使用者名稱 AS400',
  },
  misc: {
    or: '或者',
    select_place: 'Ciao {{user}}，選擇您的原籍地以繼續',
    back: '後退',
    refresh: '重新整理',
    save: '儲存',
    confirm: '確認',
    no_carton_found: '沒有找到紙箱',
    confirm_continue: '確認並繼續',
    cancel: '取消',
    close: '關閉',
    user: '使用者',
    workstation: '工作站',
    username: '使用者名稱',
    name: '姓名',
    password: '密碼',
    login: '登入',
    encoding: '編碼',
    code: '程式碼',
    ean_code: 'EAN代碼',
    certilogo: '認證標誌',
    enter_the_necessary_fields: '輸入必要的欄位以開始操作',
    associated_items: '相關項目',
    associate: '聯繫',
    associated: '已聯繫',
    verify: '核實',
    reading: '讀取中',
    processing: '進行中',
    association: '協會',
    associations: '協會',
    waiting: '等待',
    writing: '寫作',
    partial_confirm: '部分確認',
    retry: '重試',
    writing_association: 'Writing and association',
    confirmed: '已確認',
    unable_to_write: '無法為產品：{{productCode}} 寫入 epc 標籤：{{code}}',
    unable_to_get_next_epc: '無法取得下一個 epc：{{productCode}}',
    unable_to_connect_to_workstation: '無法連接到工作站',
    tag_info: '標籤資訊',
    inbound: '入境',
    outbound: '出境',
    stop: '停止',
    start: '開始',
    pause: '暫停',
    clear: '清除',
    tag_encoded_today: '今天標記編碼',
    transaction_date: '交易日期',
    select_transaction_date: '選擇交易日期',
    ready_to_receive: '準備接收',
    change_password: '更改密碼',
    logout: '登出',
    are_you_sure_to_logout: '您真的要退出嗎？',
    settings: '設定',
    roles: '身份',
    email: '電子郵件',
    place: '地方',
    items: '物品',
    description: '描述',
    color: '顏色',
    size: '尺寸',
    part: '部分',
    total_reads: 'TOTAL READS',
    total_ok: 'TOTAL OK',
    save_to_list: '儲存到列表',
    destination: '目的地',
    origin: '地域',
    new_parcel: '新包裹',
    status: '地位',
    parcel: '包裹',
    read_parcel: '閱讀包裹',
    parcels: '包裹',
    shipment: '運輸',
    creation_date: '建立日期',
    search: '搜尋',
    select_search: '搜尋...',
    detail: '細節',
    quantity: '數量（{{數量}}）',
    only_quantity: '數量',
    style: '風格',
    material: '材料',
    shipment_code_auto: '出貨代碼（自動）',
    parcel_code_auto: '包裹代碼（自動）',
    next: '下一個',
    parcels_to_receive: '待接收包裹',
    confirm_parcel: '確認包裹',
    image_missing: '影像缺失',
    image: '影像',
    loading: '載入中...',
    unknown_product: '未知產品',
    unknown_tag: '未知標籤',
    invalid_tag: '無效標籤',
    stock: '庫存',
    valid: '有效的',
    unknown: '未知',
    actions: '行動',
    reprint: '重印',
    product: '產品',
    product_items: '產品項目',
    identifiers: '身份識別',
    zone: '區',
    report_repair: '報告修復',
    reason: '原因',
    request_code_change: '請求更改程式碼',
    enable_workstation_emulation: 'Enable Workstation emulation',
    filter: '篩選',
    language: '語言',
    warning: '警告',
    success: '成功',
    info: '資訊',
    checklist: '清單',
    optional: '自選',
    phone: '電話',
    version: '版本',
    printer: '印表機',
    new_shipment: '新貨',
    upc: '統一產品程式碼',
    model_part_color_size: '型號/零件/顏色/尺寸',
    confirm_shipment: '確認出貨',
    receive: '收到',
    delete: '刪除',
    delete_association: '刪除關聯',
    change: '改變',
    from: '由',
    to: '給',
    ignored: '被忽略',
    ignored_with_reason: '有理由忽視',
    yes: '是的',
    no: '不是',
    model: 'Model',
    typology: 'Tipo',
    no_result: '沒有結果',
    expected: '預期的',
    read: '讀',
    missing: '遺失的',
    unexpected: '不應識別到',
    unexpected_parcel: '不應識別到的包裹',
    ignored_plural: '被忽略',
    serial: '序列',
    priority: '優先事項',
    group: '團體',
    required_quantity: '所需數量',
    sorted_quantity: 'Ventilated qty',
    missing_quantity: '缺少數量',
    barcode_bc: '條碼BC',
    sorted_date: 'Ventilation date',
    customer: '顧客姓名',
    list: '清單',
    list_position: '清單位置',
    select_date: '選擇日期',
    item_info: '商品資訊',
    item_events: '專案事件',
    operation_type: '操作類型',
    operation_place: '操作地點',
    loading_data: '請稍候，資料正在載入...',
    empty_item_events: '沒有找到活動',
    show_more: '展示更多',
    hide_more: '隱藏更多',
    production_order: '產品訂單',
    production_order_row: '生產訂單行',
    production_order_sku: '生產訂單 SKU',
    kill_tag: 'Kill Tag',
    item_order: '商品訂單',
    item_row: '物品行列',
    pack_code: '封裝程式碼',
    send_reads: 'Send Reads',
    items_add_inventory_success: '添加到庫存的物品',
    new: '新的',
    confirm_mission: '確認任務',
    confirm_mission_text: '您確定要確認任務嗎？',
    parziali: '部分的',
    total_quantity: '總數量',
    accepted_quantity: '接受數量',
    country_check: '國家控制',
    showcase_check: '展示控制',
    attention: '注意',
    clear_items: '繼續操作將刪除所有讀數，您要繼續嗎？',
    add_partial: '注意力',
    add_partial_text: '可能有必要創建品質保證任務',
    add_partial_already_text: '或有必要創建一個Quality Assurance Mission。 UMG 已啟用。您想覆蓋已讀取的項目嗎？',
    error_cq_mission: 'Attention, create Mission for Quality Control',
    warning_zone_items: 'IN_ANOTHER_ZONE',
    internal_tag: '內部標籤',
    external_tag: '外部標籤',
    error_tag: '錯誤標籤',
    awaiting: '等待中',
    error_logs: '錯誤logs',
    event_logs: '活動logs',
    box_closed: '盒子關閉',
    unexpected_list: '不應識別到的貨品清單',
    pending_tags: '待處理標籤',
    selected_zone: '選定區域',
    pricing: '價錢',
    send_to_sap: '傳送至 SAP',
    write_tag: '寫入標籤',
    identifier_tag: '識別標籤',
    digit_identifier_tag: '要寫入的數字標籤',
    target_epc: '目標EPC',
    retry_write_tag: '寫入過程中出錯。您想再試一次嗎？',
    ready: '已準備',
    offline: '離線',
  },
  error: {
    error: '錯誤',
    errors: '錯誤',
    forbidden: '禁止',
    not_found: '未找到',
    unauthorized: '未經授權',
    too_many_tags_read: '讀取的標籤過多（預期 {{expected}} 讀取 {{read}}）',
    too_many_checklist_types: '檢查表類型太多。確保選擇具有相同清單類型的土地。',
    rfid_discrepancies_found: '發現 RFID 差異，清除所有差異並重試或確認差異',
    certilogo_not_found: '未找到憑證“{{code}}”',
    ean_not_found: '未找到 EAN“{{code}}”',
    production_order_not_found: '未找到生產訂單“{{code}}”',
    no_inbound_zones: '無入境區域',
    no_operation_config: '未找到操作配置',
    not_supported_reading_mode: '不支援閱讀模式',
    undefined_operation_config: '操作配置不能未定義',
    certilogo_already_encoded: 'Certilogo「{{code}}」已編碼',
    tag_not_found: '未找到標籤',
    missing_printer_id: '缺少印表機 IP',
    tag_unassociated: '標籤不關聯',
    tag_type_unexpected: '不應識別到的標籤類型',
    tag_already_associated: '標籤已關聯',
    tags_not_associated: '一個或多個標籤未關聯',
    tags_mismatch: '標籤不匹配',
    workstation_could_not_be_started: '工作站 {{code}} 無法啟動',
    authentication_failed: '認證失敗',
    field_required: '欄位 {{label}} 為必填項',
    unable_to_connect_to_rfid_reader: '無法連接到 RFID 讀取器。檢查設備是否已開啟。',
    items_in_error_found: '發現錯誤的項目',
    item_not_in_inbound: '物品不在入庫中',
    item_not_in_stock: '商品無庫存',
    item_in_another_parcel: '另一個包裹中的物品',
    item_in_another_production_order: '另一個生產訂單中的項目',
    connection: '連線錯誤',
    connection_details: '伺服器沒有響應。檢查您的網際網路連線或 VPN 設定是否正常運作。',
    field_invalid_default: '欄位 {{field}} 的預設值「{{defaultValue}}」無效',
    field_invalid_default_from_endpoint: '找不到欄位 {{field}} 的值',
    sorting_already_read: '序號為 {{serial}} 的項目已在群組 {{group}} 中讀取',
    sorting_no_group: '項目不可排序',
    sorting_not_in_zone: '序號為 {{serial}} 的項目不可排序。已在區域 {{zone}}',
    sorting_unknown: '標籤 {{tag}} 無效',
    sorting_unexpected: '意外標籤',
    no_item_configuration: '未找到項目配置',
    item_creation_error: '物品創建錯誤',
    couldn_find_production_order: '找不到生產訂單',
    couldn_find_production_order_row: '找不到生產訂單行',
    missing_operation_option: '缺少操作選項：{{error}}',
    item_session_list_create: '未建立的列表',
    item_session_list_create_session: '未建立的會話列表',
    item_session_list_confirm: '未確認名單',
    item_session_list_confirm_session: '未確認的會話列表',
    form_validation: '驗證表',
    form_validation_message: '欄位填寫錯誤',
    select_place_error: '無法保存原籍地',
    self_user_data: '無法保存您的數據',
    encoding_no_tags: '沒有讀取更多標籤。手術終止',
    only_virgin_tag: '僅偵測到原始標籤',
    tags_mismatch_error: '偵測到太多產品',
    tags_errors: '標籤錯誤',
    value_not_valid: '輸入的值無效',
    write_tag: '寫入標籤期間發生未知錯誤',
    WRONG_PRODUCTION_ORDER: '生產訂單錯誤',
    WRONG_PLACE: '錯誤的地方',
    PRODUCTION_ORDER_ROW_CREATION_ERROR: '生產訂單行創建錯誤',
    force_tag_associated: '標籤已關聯。重新關聯？',
    operation_confirm_error: '確認操作時發生錯誤',
    missing_mandatory_qc: '沒有品質控制。繼續？',
    wrong_qc_status: 'The product has been declared KO. Proceed?',
    wrong_qc_size: '檢查尺寸。繼續？',
    wrong_qc_size_payload: '檢查尺寸。編碼尺寸：{{productSize}} - 物理尺寸：{{manualSize}}。繼續？',
    wrong_qc_model: '檢查型號。繼續？',
    start_checklist: '取消啟動清單',
    confirm_with_unexpected: '無法確認不應識別到的標籤',
    confirm_with_overqty: '無法確認數量過多的標籤',
    rfid_reader_initialization: 'RFID 讀取器初始化期間出現未知錯誤',
    remove_sap_item_error: '無法刪除項目',
    no_asn_selected: '未選擇 ASN',
    submit_asn: '提交 ASN 時出錯',
  },
  messages: {
    no_operations_found: '沒有找到任何操作。檢查控制面板上的使用者權限',
    no_workstations_found: '未找到工作站',
    no_workstation_selected: '未選擇工作站',
    are_you_sure_to_reset_daily_items: '您確定要重置日常項目嗎？',
    bring_an_item_to_antenna: '將物品靠近天線',
    press_start_to_read: '按下“開始”按鈕開始讀取 RFID 產品',
    reading_in_progress: '閱讀進行中',
    waiting_for_product: '等待產品...',
    paste_comma_separeted_tags: '貼上以逗號分隔的標籤，然後按下返回鍵...',
    connection_workstation_progress: '正在連接到工作站..',
    operation_success: '確認操作成功',
    outbound_success: '出境成功確認',
    inbound_success: '入站確認成功',
    pack_success: '包裝成功確認',
    transfer_to_zone_success: '轉移至區域已成功確認',
    simple_item_list_success: '簡單物品清單已成功確認',
    list_empty: '列表為空',
    looks_like_anything_in_list: '您的清單中似乎沒有任何內容',
    generic_success: '{{code}} 已成功確認',
    generic_success_canceled: '{{code}} 成功取消',
    generic_error: '{{code}}錯誤',
    fill_all_fields_and_search_an_item_configuration: '填寫所有欄位並蒐索項目配置',
    encoding_success: '編碼成功確認',
    read_some_identifier_to_start_verification: '讀取一些標識符以開始驗證',
    warning_items_found: '發現警告項目',
    are_you_sure_you_want_to_confirm: '您確定要確認嗎？',
    item_session_list_confirm: '名單確認成功',
    operation_in_progress: '操作正在進行中，請稍候...',
    sorting_error_zones: '"Origin zone not valued, impossible to continue with the ventilation"',
    connection_in_progress: '連接正在進行中...',
    checklist_there_are: '有',
    checklist_missings: '遺失物品',
    checklist_overqty: '超額物品',
    checklist_unexpecteds: '不應識別到的物品',
    checklist_confirm_mismatch_question: '您確定還要確認嗎？',
    force_update_content: '您確定要強制傳送到 SAP 嗎？',
    connection_to_station_in_progress: '正在連接至電台',
    tag_killed: '標籤被殺',
    tag_deleted: '標籤已刪除',
    write_tag: '寫入標籤成功',
  },
  placeholder: {
    search_shipment_parcel: '搜尋貨件或包裹',
    search_product_parcel: '搜尋產品或包裹',
  },
  confirm: {
    discrepancy_title: '包裹有差異',
    discrepancy_message: '發現產品存在差異。您確定要確認包裹嗎？',
    confirm_delete_association: '您確定要刪除該項目關聯嗎？',
    confirm_delete_operation_title: '刪除{{操作}}',
    confirm_delete_operation: '您想刪除該操作嗎？所有讀取的項目都將遺失。',
    confirm_delete_item_association: '您確定要刪除該項目關聯嗎？',
    confirm_kill_tag: '您確定要刪除此標籤嗎？',
    confirm_operation_title: '確認{{操作}}',
    confirm_operation_text: '您想確認操作嗎？',
    confirm_operation_text_irreversible: '您想確認操作嗎？此操作無法恢復。',
    confirm_operation_text_partial_confirm: '將讀數發送到 JDE。您確定要繼續嗎？',
    post_confirm_action_title: '繼續',
    post_confirm_action_keep_input: '操作完成。執行另一項操作？',
    post_confirm_action_change_input: '操作完成。執行另一項操作？',
  },
  labels: {
    draft: '草稿',
    ready_to_send: '準備發送',
    send_await: '待辦的',
    outbound_error: '出站錯誤',
    sent: '傳送',
    in_inbound: '入境時',
    receive_await: '待辦的',
    ready_to_receive: '收到',
    inbound_error: '入站錯誤',
    received: '已收到',
    in_transit: '在途中',
  },
  report_tbox: {
    umg: 'Umg',
    ddt_number: 'DDT N.',
    creation_date: '日期',
    qt_totale: '總數量',
    qt_accettata: '接受數量',
    qt_scartata: '拒收數量',
    supplier: '供應商',
    notes: '筆記',
  },
  error_messages: {
    missing_workstation_place: '缺少工作站地點',
  },
  custom: {
    countTBoxParcel: '包裹',
    countTBoxDDT: 'DDT',
    BARCODE_READ: '條碼讀取',
    RFID_START_READING: '開始讀取',
    RFID_STOP_READING: '停止讀取',
    RESULT_EVENT: '活動結果',
    TRACE: '追查來源',
    INFO: '資訊',
    WARNING: '警告',
    ERROR: '錯誤',
    deleteSession: '刪除包裹',
    deleteSessionMessage: '您確定要刪除該包裹嗎？',
    deleteList: '刪除DDT',
    deleteListMessage: '您確定要刪除 ddt 嗎？',
    WAM: 'SKU',
    sap_warning: 'SAP 警告',
    total_list: '總清單',
    total_parcel: '總包裹數',
    order_number: '訂單編號',
    addon: '添加在',
    confirm_force_received_text: '此運送已經確認。您要將其從列表中刪除嗎？',
  },
  imbustatrice: {
    and: '和',
    unkwon_tag: '未知標籤',
    dynamic_tag: '{{身份}}標籤',
    dynamic_tag_missing: '{{身份}} 標籤缺失',
    too_many_dynamic_tags: '{{身份}} 標籤過多',
    dynamic_tag_already_associated: '{{身份}} 標籤已關聯',
    wrong_tag: '標籤錯誤',
  },
  identifier_error: {
    ALREADY_ASSOCIATED_TAG: '已關聯',
    WRONG_QC_STATUS: '品質控制狀態錯誤',
    WRONG_QC_SIZE: '品質控制尺寸錯誤',
    WRONG_QC_MODEL: '品質控制模型錯誤',
    MISSING_MANDATORY_QC: '缺乏品質控制',
    MISSING_PRODUCT_INFO: '缺少產品資訊',
    WRONG_TAG: '標籤錯誤',
    UNKNOWN_TAG: '未知標籤',
    WRONG_PRODUCT: '錯誤的產品',
    MISSING_MANDATORY_TAG: '缺少強制標籤',
    MISSING_OPTIONAL_TAG: '缺少可選標籤',
    EXTRA_TAG: '額外標籤',
    TAG_MISMATCH: '標籤不匹配',
    PRODUCTIONORDERROW_MISMATCH: '生產訂單行關聯不匹配',
    MISSING_SYSTEM_TAG: '未知標籤',
  },
  statuses: {
    RESTORE_VARIANT: 'RESTORE TYPOLOGY',
  },
}
