import { merge as _merge } from 'lodash'
import en from 'core/translations/en'

export default _merge(en, {
  titles: {
    outbound_bthp: 'Outbound BTHP Certificate',
  },
  simple_parcel_list: {
    to_read: 'To Read',
    ok: 'Ok',
    force: 'Force',
    force_parcel: 'Force parcel {{code}}',
    force_parcel_desc: 'Insert user PIN to force parcel',
    forced: 'Forced',
    unexpected: 'Unexpected',
    remove: 'Remove',
    invalid_pin: 'User PIN invalid',
  },
  create_parcel_gate: {
    width: 'Width',
    height: 'Height',
    depth: 'Depth',
    tare: 'Tare',
    description: 'Description',
  },
  misc: {
    sorted_quantity: 'Sorted qty',
    sorted_date: 'Sorted date',
  },
  serverError: {
    WRONG_ITEM_SUPPLIER: 'Some read articles cannot be taken over by the supplier.',
    QUANTITY_MISMATCH_ERROR: 'Quantities entered do not correspond to the quantities detected.',
    ZONE_MISMATCH_ERROR: 'Zone entered does not correspond to the zone detected.',
  },
  error_messages: {
    missing_workstation_place: 'Missing workstation place',
  },
  custom: {
    barcode: 'Barcode BC',
    barcodeBCTrim: 'Barcode BC trim',
    barcodeJDE: 'Barcode JDE',
    barcodeROQ: 'Barcode ROQ',
    barcodeROQTrim: 'Barcode ROQ trim',
    class: 'Class',
    department: 'Department',
    dimensionSystem: 'Custom class',
    ean: 'EAN',
    eanIdPadre: 'Father EAN',
    itemId: 'Item Id',
    stagioneROQ: 'ROQ season',
    subclass: 'Subclass',
    subdepartment: 'Subdepartment',
    variantType: 'Typology ',
    rfidEnabled: 'RFID enabled',
    stato: 'Status',
    ricondizionamentoFornitore: 'Recondition supplier',
    codiceShelf: 'Stock Compartment',
    montante: 'Section',
    ripiano: 'Bin',
    scaffale: 'Bay',
    model: 'Modello',
    sizeCode: 'Size code',
    sizeDescription: 'Size description',
    serial: 'Serial',
    identifier: 'Serial',
    product: 'Barcode BC',
    shift: 'Shift',
    destination: 'Destination',
    regDate: 'Date',
    listPosition: 'List position',
    list: 'List',
    customerName: 'Customer Name',
    regTime: 'Time',
    event: 'Event',
    customer: 'Customer',
    isCarryover: 'Carryover',
    carryoverValue: 'Carryover',
    madeIn: 'Made in',
    collectionCode: 'Collection',
    collectionDescription: 'Collection Description',
    collectionYear: 'Year',
    fatherProductValue: 'Father Product',
    genderValue: 'Gender',
    genderValueDescription: 'Gender Description',
    line: 'Brandline',
    lineDescription: 'Line Description',
    marchioDescription: 'Marchio Description',
    marchioValue: 'Marchio',
    merchandiseClassCode: 'Category',
    merchandiseClassDescription: 'Category Description',
    merchandiseSubclassCode: 'Sub-Category',
    merchandiseSubclassDescription: 'Sub-Category Description',
    modelPartColor: 'Model Part Color',
    modelPartColorSizeValue: 'Model Part Color Size',
    isPermanent: 'Permanent',
    permanentValue: 'Permanent',
    isSeasonal: 'Seasonal',
    seasonalValue: 'Seasonal',
    sleeveTypeValue: 'Sleeve Type',
    confirm_force_received_text: 'This shipment has already been confirmed. Would you like to remove it from the list?',
  },
})
